import { BaseElement, html, css } from './components/base-element.js';
import { default as i18next, init as i18nextInit } from './localize/i18next.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import { init as initFirebaseRedux } from '@dw/firebase-redux';
import { KerikaCompositeDialog } from './components/common/kerika-composite-dialog.js'
import './components/app/app-drawer';
import './components/base-element.js';

import './components/app/splash-screen.js';
import './components/app/permission-denied';
import './components/common/kerika-snackbar.js';

// Service worker
import { installServiceWorker } from './install-sw-with-firebase.js';
import { getCookieVal } from './utils.js';

// Redux Imports
import * as router from './redux/router';
import * as app from './redux/app';
import * as auth from './redux/auth';
import store from './redux/store.js';
import { initializeApp } from 'firebase/app';

//Theme Imports
import theme from './theme/theme.js';

//Lodash
import get from 'lodash-es/get';

// Sentry
import { init as intializeSentry, configureScope as configureScopeForSentry } from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing"; // Must import second
import { CaptureConsole } from '@sentry/integrations/esm/captureconsole';
import { Dedupe as DedupeIntegration } from '@sentry/integrations/esm/dedupe';

/**
 * Behaviors:
 *  - Integrates app level modules & tools e.g. service worker, router, auth, sentry
 *  - Integrates app drawer.
 *  - Integrates app level pages & dialogs
 *  - Pages
 *    - Splash Screen
 *    - Under Maintenance
 *    - Not found
 *    - Permission Denied
 *    - User list page
 *    - Account list page
 *    - Invoice list page
 *
 *  - Dialogs
 *    - Manage user dialog
 *      - In desktop its modal dialog where in mobile & tablet its fit dialog.
 *    - Manage Account dialog
 *      - In desktop, its modal dialog where in tablet & mobile its fit dialog.
 *    - Manage Invoice dialog
 *    - Invoice Actions dialog
 *      - invoice-mark-as-closed-dialog
 *      - invoice-mark-as-paid-dialog
 *      - invoice-update-subscriptions-dialog
 *      - invoice-change-remark-diloag
 *      - invoice-delete-dialog
 */

class AppShell extends BaseElement {
  constructor() {
    super();
    this.doNotDelayRendering = true; 

    this._appConfig = app.selectors.config(store.getState());


    // Intialize sentry error tracking.
    this._initializeSentryErrorTracking();

    // Initialize Firebase app
    const firebaseConfig = {
      apiKey: this._appConfig.firebaseApiKey,
      authDomain: this._appConfig.firebaseAuthDomain,
      projectId: this._appConfig.firebaseProjectId,
      databaseURL: this._appConfig.firebaseDatabaseURL,
    };

    this._firebaseApp = initializeApp(firebaseConfig);

    // Intialzie firestore redux.
    firestoreRedux.init({ store, firebaseApp: this._firebaseApp });

    //Initialize firebase-redux to read data from firebase and store into redux state
    initFirebaseRedux(store, this._firebaseApp);

    //Authentication
    auth.actions.init();

    i18nextInit();
    this.i18nextNameSpaces = ['users'];
  }

  connectedCallback(){
    super.connectedCallback();
    KerikaCompositeDialog.setAppendTo(this);
  }

  firstUpdated(changedProps) {
    super.firstUpdated && super.firstUpdated(changedProps);
    if (window.navigator.serviceWorker && !this.__isDisableServiceWorker()) {
      installServiceWorker(this._firebaseApp);
    }
  }

  static properties = {
    /**
     * Current page name. Its computed from the router data.
     */
    _pageName: { type: String },

    /**
     * Current app level dialog name. Its computed from the router data.
     */
    _dialogName: { type: String },

    /**
     * `false` when current user is not admin.
     */
    _admin: { type: Boolean },

    /**
     * Used to open `account-team-member-dialog`.
     * Computed from dialog query param `memberAction`. Possible values: 'view'
     */
    _memberAction: { type: String },

    /**
     * Used to lazy-loads & open subscription dialogs. e.g. `change-credit-balance-dialog` or `change-plan-dialog`.
     * Possible values: `change-credit-balance` & `change-plan`.
     */
    _subscriptionAction: { type: String },

    /**
     * `true` when any dialog is opened.
     */
    _dialogOpened: { type: Boolean, reflect: true, attribute: 'dialog-opened'},

    /**
     * Used to open `invoice action dialog`.
     * Computed from dialog query param `invoice-action`. Possible values: 'mark-as-closed', 'mark-as-paid', 'update-subscriptions', 'change-remark', 'delete'
     */
    _invoiceAction: { state: true },

    /**
     * Current logged in user.
     */
    _currentUser: { type: Object },

    /**
     * Used to open `confirm view as dialog`.
     */
    _userAction: { type: String }
  };

  static styles = [
    super.styles,
    theme,
    css`
      :host {
        min-height: 100vh;
        display: flex;
        background: var(--mdc-theme-background);
      }

      :host([layout="MOBILE"]),
      :host([layout="TABLET"]) {
        --navigation-bar-container-margin: 0;
      }

      :host([layout="MOBILE"]) {
        background: var(--mdc-theme-surface);
      }

      main {
        min-height: 100vh;
        flex: 1;
        overflow-x: hidden;
      }

      under-maintenance,
      splash-screen,
      permission-denied {
        position: fixed;
        inset: 0;
      }

      .pages > * {
        min-height: 100vh;
      }
    `,
  ];

  willUpdate(changedProps) {
    super.willUpdate && super.willUpdate(changedProps);
    if(changedProps.has('_currentUser') && this._currentUser){
      this._trackLoginUserOnSentry();
    }

    if (changedProps.has('_pageName') && this._pageName) {
      this.__loadFragment(this._pageName);
    }

    if (changedProps.has('_dialogName') && this._dialogName) {
      this.__loadFragment(this._dialogName);
    }
    if (changedProps.has('_memberAction') && this._memberAction === 'view') {
      this.__loadFragment('MEMBER_VIEW');
    }
    if (changedProps.has('_invoiceAction') && this._invoiceAction ) {
      switch(this._invoiceAction) {
        case 'mark-as-closed':
          this.__loadFragment('MARK_AS_CLOSED');
          break;
        case 'mark-as-paid':
          this.__loadFragment('MARK_AS_PAID');
        case 'change-remark':
          this.__loadFragment('CHNAGE_REMARK');
        case 'update-subscriptions':
          this.__loadFragment('UPDATE_SUBSCRIPTIONS');
          break;
        case 'delete':
          this.__loadFragment('INVOICE_DELETE');
          break;
      }
    }

    if (changedProps.has('_subscriptionAction') && this._subscriptionAction === 'change-plan') {
      this.__loadFragment('CHANGE_PLAN')
    }
    if (changedProps.has('_subscriptionAction') && this._subscriptionAction === 'change-credit-balance') {
      this.__loadFragment('CHANGE_CREDIT_BALANCE')
    }

    if (changedProps.has('_userAction') && this._userAction) {
      if (this._userAction === 'view-as') {
        this.__loadFragment('VIEW_AS');
      }
    }
  }

  render() {
    return html`
      ${this._spashScreenTemplate}
      ${this._underMaintenanceTemplate}
      ${this._appDrawerTemplate}

      <main>
        ${this._pagesTemplate}
        ${this._dialogsTemplate}
      </main>
      <slot></slot>

      <!-- Snack bar -->
      <kerika-snackbar class="snackbar" .mobile=${this._layout === 'MOBILE'}></kerika-snackbar>
    `;
  }

  get _spashScreenTemplate() {
    if (this._pageName !== 'SPLASH_SCREEN') {
      return;
    }

    return html`<splash-screen></splash-screen>`;
  }

  get _underMaintenanceTemplate() {
    if (this._pageName !== 'UNDER_MAINTENANCE') {
      return;
    }

    return html`<under-maintenance></under-maintenance>`;
  }

  get _appDrawerTemplate() {
    if (this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN' || this._pageName === 'PERMISSION_DENIED') {
      return;
    }
    return html`<app-drawer></app-drawer>`;
  }

  get _notFoundTemplate() {
    if (this._pageName !== 'NOT_FOUND') {
      return;
    }

    return html`<not-found></not-found>`;
  }

  get _usersListPageTemplate() {
    if (this._pageName !== 'USERS') {
      return;
    }

    return html`<user-list-page></user-list-page>`;
  }

  get _accountsListPageTemplate() {
    if (this._pageName !== 'ACCOUNTS') {
      return;
    }
    return html`<account-list-page></account-list-page>`;
  }

  get _nonProfitDomainsPageTemplate() {
    if (this._pageName !== 'NON_PROFIT_DOMAINS') {
      return;
    }

    return html`<non-profit-domain-list-page></non-profit-domain-list-page>`;
  }

  get _publicDomainsPageTemplate() {
    if (this._pageName !== 'PUBLIC_DOMAINS') {
      return;
    }

    return html`<public-domain-list-page></public-domain-list-page>`;
  }

  get _invoicesPageTemplate() {
    if (this._pageName !== 'INVOICES') {
      return;
    }
    return html`<invoice-list-page></invoice-list-page>`;
  }

  get _systemLanguagePageTemplate() {
    if (this._pageName !== 'SYSTEM_LANGUAGE') {
      return;
    }
    return html`<system-language-list-page></system-language-list-page>`;
  }

  get _maliciousUrlListPageTemplate() {
    if (this._pageName !== 'MALICIOUS_URL') {
      return;
    }
    return html`<malicious-url-list-page></malicious-url-list-page>`;
  }

  get _permissionDeniedTemplate() {
    if (this._pageName !== 'PERMISSION_DENIED') {
      return;
    }
    return html`
    <permission-denied></permission-denied>
    `
  }

  get _pagesTemplate() {
    if (this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN') {
      return;
    }
    return html`
      <div class="pages">
        ${this._notFoundTemplate}
        ${this._usersListPageTemplate}
        ${this._accountsListPageTemplate}
        ${this._nonProfitDomainsPageTemplate}
        ${this._publicDomainsPageTemplate}
        ${this._invoicesPageTemplate}
        ${this._permissionDeniedTemplate}
        ${this._systemLanguagePageTemplate}
        ${this._maliciousUrlListPageTemplate}
      </div>
    `;
  }

  get _manageUserDialogTemplate() {
    if (this._dialogName !== 'MANAGE_USER') {
      return;
    }

    return html`
    <manage-user-dialog
      .opened=${true}
      .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}>
    </manage-user-dialog>
    ${this._userActionDialogTemplate}`;
  }

  get _manageAccountDialogTemplate() {
    if (this._dialogName !== 'MANAGE_ACCOUNT') {
      return;
    }

    return html`
    <manage-account-dialog
      .opened=${true}
      .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
    ></manage-account-dialog>`;
  }

  get _memberDialogTemplate() {
    if(this._memberAction !== 'view'){
      return;
    }
    return html `
    <account-team-member-dialog
      .opened=${true}
      .type=${this._layout === 'MOBILE' ? 'fit' : 'modal'}
    ></account-team-member-dialog>
    `
  }

  get _manageInvoiceDialogTemplate() {
    if (this._dialogName !== 'MANAGE_INVOICE') {
      return;
    }

    return html`
    <manage-invoice-dialog
    .opened=${true}
    .type=${'modal'}
    .fitHeight=${true}
    .placement=${this._layout === 'MOBILE' ? 'bottom' : 'center' }
    .headerStyle=${ this._layout === 'MOBILE' ? 'BACK' : 'CLOSE'}
    ></manage-invoice-dialog>
    ${this._invoiceActionDialogTemplate}
    `;
  }

  get _userActionDialogTemplate() {
    if (this._userAction !== 'view-as') {
      return;
    }

    return html`
      <cofirm-view-as-dialog
        .opened=${true}
        .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
      ></cofirm-view-as-dialog>
    `;
  }

  get _invoiceActionDialogTemplate() {
    if (!this._invoiceAction) {
      return;
    }

    switch (this._invoiceAction) {
      case 'mark-as-closed':
        return html`
        <invoice-mark-as-closed-dialog
          .opened=${true}
          .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
        ></invoice-mark-as-closed-dialog>`;
      case 'mark-as-paid':
        return html ` <invoice-mark-as-paid-dialog
          .opened=${true}
          .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
        ></invoice-mark-as-paid-dialog>`
        case 'change-remark':
          return html`
          <invoice-change-remark-diloag
            .opened=${true}
            .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
          ></invoice-change-remark-diloag>`;
      case 'update-subscriptions':
        return html`
        <invoice-update-subscriptions-dialog
        .opened=${true}
        .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
        ></invoice-update-subscriptions-dialog>`;
      case 'delete':
        return html`
        <invoice-delete-dialog
        .opened=${true}
        .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}>
        </invoice-delete-dialog>`;
    }
  }

  get _changePlanDialogTemplate() {
    if(this._subscriptionAction !== 'change-plan') {
      return;
    }
    return html`
      <change-plan-dialog
        .opened=${true}
        .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'}
      ></change-plan-dialog>
    `
  }

  get _changeCreditBalanceTemplate() {
    if(this._subscriptionAction !== 'change-credit-balance') {
      return;
    }
    return html`
      <change-credit-balance-dialog
        .opened=${true}
        .type=${this._layout === 'DESKTOP' ? 'modal' : 'fit'} 
      ></change-credit-balance-dialog>
    `
  }

  get _dialogsTemplate() {
    if (this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN') {
      return;
    }
    return html`
      ${this._manageUserDialogTemplate}
      ${this._manageAccountDialogTemplate}
      ${this._memberDialogTemplate}
      ${this._manageInvoiceDialogTemplate}
      ${this._changePlanDialogTemplate}
      ${this._changeCreditBalanceTemplate}
    `;
  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('_pageName') || changedProps.has('_dialogName')) {
      if(this._pageName === 'INVOICES' && this._dialogName !== 'MANAGE_INVOICE' && this._accountId && this._invoiceId) {
        const accountId = this._accountId;
        const invoiceId = this._invoiceId;
        router.dwRouter.navigatePage('INVOICES', {}, true);
        router.dwRouter.navigateDialog('MANAGE_INVOICE', { accountId, invoiceId });
      }
    }
  }

  /**
   * Init sentry error tracking code.
   * @protected
   */
  _initializeSentryErrorTracking() {
    //Return if application is run in development environment
    if (this._appConfig.environment === 'development') {
      return;
    }

    const sEnvironment = this._appConfig.environment || 'test';
    const sVersion = this._appConfig.version || '';

    //Integrate Sentry error tracking tool (https://docs.sentry.io/quickstart)
    intializeSentry({
      dsn: this._appConfig.sentryDSN,
      release: sVersion,
      environment: sEnvironment,
      attachStacktrace: true,
      integrations: [
        new BrowserTracing(),
        new CaptureConsole({
          levels: ['error'],
        }),
        new DedupeIntegration(),
      ],
      ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
    });
  }

  /**
   * Tracks user id on Sentry with error logs.
   */
  _trackLoginUserOnSentry() {
    //Return if application is run in development environment
    if (this._appConfig.environment === 'development') {
      return;
    }
    const id = this._currentUser.id;
    configureScopeForSentry((scope) => { scope.setUser({ id }) });
  }

  async __loadFragment(module) {
    try {
      switch (module) {
        case 'SPLASH_SCREEN':
          await import('./components/app/splash-screen.js');
          break;
        case 'NOT_FOUND':
          await import('./components/app/not-found.js');
          break;
        case 'UNDER_MAINTENANCE':
          await import('./components/app/under-maintenance.js');
          break;
        case 'USERS':
          await import('./components/users/user-list-page.js');
          break;
        case 'ACCOUNTS':
          await import('./components/accounts/account-list-page.js');
          break;
        case 'NON_PROFIT_DOMAINS':
          await import('./components/non-profit-domains/non-profit-domain-list-page.js');
          break;
        case 'PUBLIC_DOMAINS':
          await import('./components/public-domains/public-domain-list-page.js');
          break;
        case 'INVOICES':
          await import('./components/invoices/invoice-list-page.js');
          break;
        case 'SYSTEM_LANGUAGE':
          await import('./components/system-language/system-language-list-page.js');
          break;
        case 'MALICIOUS_URL':
          await import('./components/malicious-url/malicious-url-list-page.js');
          break;
        case 'MANAGE_USER':
          await import('./components/users/manage-user-dialog.js');
          break;
        case 'MANAGE_ACCOUNT':
          await import('./components/accounts/manage-account-dialog.js');
          break;
        case 'PERMISSION_DENIED':
          await import('./components/app/permission-denied.js');
          break;
        case 'MANAGE_USER':
          await import('./components/users/manage-user-dialog.js');
          break;
        case 'MEMBER_VIEW':
          await import('./components/accounts/account-team-member-dialog.js');
          break;
        case 'MANAGE_INVOICE':
          await import('./components/invoices/manage-invoice-dialog.js');
          break;
        case 'MARK_AS_CLOSED':
          await import('./components/invoices/invoice-mark-as-closed-dialog.js');
          break;
        case 'MARK_AS_PAID':
          await import('./components/invoices/invoice-mark-as-paid-dialog.js');
          break;
        case 'CHNAGE_REMARK':
          await import('./components/invoices/invoice-change-remark-diloag.js');
          break;
        case 'UPDATE_SUBSCRIPTIONS':
          await import('./components/invoices/invoice-update-subscriptions-dialog.js');
          break;
        case 'INVOICE_DELETE':
          await import('./components/invoices/invoice-delete-dialog.js');
          break;
        case 'CHANGE_PLAN':
          await import('./components/subscription/change-plan-dialog.js');
          break;
        case 'CHANGE_CREDIT_BALANCE':
          await import('./components/subscription/change-credit-balance-dialog.js');
          break;
        case 'VIEW_AS':
          await import('./components/users/confirm-view-as-dialog.js');
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(`Failed to import file for module ${module}`, err);
    }
  }

  /**
   * Sets `_pageName` based on the state.
   */
  __getPageName() {
    if (!this._currentUser) {
      return 'SPLASH_SCREEN';
    }

    if (this._underMaintenance) {
      return 'UNDER_MAINTENANCE';
    }

    if (!this._admin) {
      return 'PERMISSION_DENIED';
    }

    return get(this._page, 'name');
  }

  /**
   * Sets `_dialogName` based on the state.
   */
  __getDialogName() {
    return get(this._dialog, 'name');
  }

  /**
   * Service worker is disable or not.
   * @returns {Boolean} `true` When current app is installed app
   *                            OR applition run locally using localhost OR proxy-url,
   *                    `false` otherwise.
   * @private
   */
  __isDisableServiceWorker() {
    return this.__isEnabledProxy() || this.__isLocalEnvironment();
  }

  /**
   * Application locally run or not.
   * @private
   */
  __isLocalEnvironment() {
    return window.location.host.includes('localhost');
  }

  /**
   * Local proxy enabled or not.
   * @private
   */
  __isEnabledProxy() {
    return getCookieVal('proxy_url');
  }

  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);
    this._underMaintenance = app.selectors.isUnderMaintenance(state);
    const pageParams = router.selectors.pageParams(state);
    this._accountId = get(pageParams, 'accountId');
    this._invoiceId = get(pageParams, 'invoiceId');
    this._page = get(state, 'router.page');
    this._dialog = get(state, 'router.dialog');
    this._currentUser = auth.selectors.currentUser(state);
    this._admin = auth.selectors.isAdmin(state);
    this._pageName = this.__getPageName();
    this._dialogName = this.__getDialogName();
    this._memberAction = router.selectors.dialogParams(state).memberAction;
    this._subscriptionAction = router.selectors.dialogParams(state).subscriptionAction;
    const action = router.selectors.actionName(state);
    this._invoiceAction = router.selectors.dialogParams(state)['invoice-action'];
    this._userAction = router.selectors.dialogParams(state)['user-action'];
    this._dialogOpened = !!this._dialogName || !!action || !!this._memberAction || !!this._invoiceAction;
  }
}

customElements.define('app-shell', AppShell);
